import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from 'gatsby'

const AnimalPage = () => (
  <Layout>
    <nav class="navbar">
      <Link class="navbar-brand" to="/">Volver</Link>
    </nav>
    <SEO title="Animal" />
    <img className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-4.jpg" alt="Arbol" />
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 mt-5">
        <video controls autoPlay loop className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/tuno560.mp4" />

      </div>
      <div className="col-12 col-md-8 mt-5">
        <h1>Acto Animal</h1>
        <p>
          Un principio de año, al salir del templo de la Santísima Cruz Tun de Xocén un perro se sumó al camino de vuelta al centro del pueblo, era un trayecto largo, hacía calor pero las nubes nos acompañaban en la caminata. El perro que en ese mismo lugar un
          año Volver había lanzado sus ladridos por la presencia extraña, ahora nos acompañaba. Al detener el paso él también paraba, no reclamaba atención, sólo nos acompañaba. En un punto del camino varios perros asomaron sus narices a nuestro
          paso, al ver que nuestro compañero de ruta encabezaba la caminata no dudaron en reclamar su territorio, vino la bulla y parecía no tener resolución, cuando repentinamente cesaron los ladridos y retomaron su rumbo, el perro, nuestro
          guía, nos consintió el paso y emprendimos de nuevo la marcha. Faltaba poco, la vía por la que caminábamos dejaba de ser rápida para encontrarnos con las casas que indicaban que nuestro destino estaba cerca, el perro sin más, se detuvo,
          esperó a que notáramos su pronunciamiento y emprendió el camino de vuelta.
                    <br /><br /> Regresó al lugar donde pasa los días viendo a aquellos que llegan a adorar a la Santísima Cruz Tun, donde los animales le cantaban al inicio de los tiempos “porque en ese tiempo sólo ellos lo adoraban” <span className="no-wrap">(Terán y Rasmussen,
      2005)</span>
        </p>
      </div>
    </div>
  </Layout>
)

export default AnimalPage
